import React, { useRef } from "react";
import styled, { css } from "styled-components";
import Image from "gatsby-image";
import BackgroundVideo from "./background-video";

const Root = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  filter: brightness(0.7);
`;

const BackgroundColor = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const BackgroundImage = styled(Image)`
  & img {
    object-fit: cover;
    object-position: center ${({ anchor }) => anchor} !important;
  }
`;

const Background = ({ slides, index, onLoad }) => {
  const firstLoad = useRef(true);
  return (
    <Root role="presentation" aria-hidden tabIndex={-1}>
      {slides.map(({ type, ...rest }, arrayIndex) => {
        const handleFirstLoad = () => {
          if (index === arrayIndex && firstLoad.current) {
            firstLoad.current = false;
            onLoad();
          }
        };
        const hide = css`
          width: 100%;
          height: 100%;
          position: absolute !important;
          opacity: ${index === arrayIndex ? 1 : 0.001};
          transition: opacity 0.5s;
        `;
        switch (type) {
          case "text":
            handleFirstLoad();
            return (
              <BackgroundColor
                backgroundColor={rest.backgroundColor}
                css={hide}
              />
            );
          case "image":
            return (
              <BackgroundImage
                fluid={rest.fluidImage}
                css={hide}
                anchor={rest.anchor}
                placeholderStyle={{ filter: "blur(14px)" }}
                loading="eager"
                onLoad={handleFirstLoad}
              />
            );
          case "video":
            return (
              <BackgroundVideo
                url={rest.url}
                preload={Math.abs(arrayIndex - index) <= 1}
                playing={arrayIndex === index}
                css={hide}
                onLoad={handleFirstLoad}
              />
            );
          default:
            return undefined;
        }
      })}
    </Root>
  );
};

Background.propTypes = {};

export default Background;
