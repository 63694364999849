import React from "react";
import styled from "styled-components";
import Brand from "../common/brand";

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  opacity: ${({ show }) => (show ? 1 : 0.001)};
  background: white;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
`;

const Overlay = ({ show }) => {
  return (
    <Root show={show}>
      <Brand
        variant="logo"
        css={`
          color: black;
          height: 200px;
        `}
      />
    </Root>
  );
};

Overlay.propTypes = {};

export default Overlay;
