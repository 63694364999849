import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledVideo = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const BackgroundVideo = ({
  url,
  start = 0,
  playing,
  preload,
  className,
  onLoad,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (playing) {
      ref.current.currentTime = start;
      // eslint-disable-next-line no-unused-expressions
      ref.current.play()?.catch(() => onLoad());
    } else {
      // eslint-disable-next-line no-unused-expressions
      ref.current.pause();
    }
  }, [playing, start, onLoad]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <StyledVideo
      className={className}
      ref={ref}
      src={url}
      disablePictureInPicture
      loop
      muted
      preload={preload ? "auto" : "metadata"}
      onPlaying={() => onLoad()}
      playsInline
    />
  );
};

export default BackgroundVideo;
