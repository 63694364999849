import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

import Brand from "../common/brand";
import Text from "../common/text";
import Background from "./background";

import { ReactComponent as ChevronLeft } from "../../svgs/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../svgs/chevron-right.svg";
import Overlay from "./overlay";

const Root = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  color: rgb(255, 255, 255);
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  grid-area: center;
  justify-self: center;
  align-self: center;
`;

const Bottom = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  grid-area: bottom;
  justify-self: center;
  align-self: end;
`;

const Left = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  justify-self: left;
  align-self: center;
`;

const Right = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  justify-self: right;
  align-self: center;
`;

const Content = styled(Link)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Chevron = styled.svg`
  display: block;
  cursor: pointer;
  padding: 32px;
  border-radius: 100%;
  user-select: none;
  height: 90px;
  width: 90px;
  ${({ theme }) => theme.media.mobile} {
    height: 80px;
    width: 80px;
  }
  &:focus {
    outline: none;
  }
`;

const Logo = styled.img`
  height: 100px;
  ${({ theme }) => theme.media.mobile} {
    height: 75px;
  }
`;

const HomeIndex = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [autoplaying, setAutoplaying] = useState(true);
  const { title, subtitle, timeout, logo, linkURL } = slides[slideIndex];

  const resumeTimerID = useRef();

  const previousSlide = (manual) => {
    setSlideIndex((slideIndex - 1 + slides.length) % slides.length);
    if (manual) {
      clearTimeout(resumeTimerID.current);
      setTimeout(() => setAutoplaying(true), 15000);
      setAutoplaying(false);
    }
  };
  const nextSlide = (manual) => {
    setSlideIndex((slideIndex + 1) % slides.length);
    if (manual) {
      clearTimeout(resumeTimerID.current);
      setTimeout(() => setAutoplaying(true), 15000);
      setAutoplaying(false);
    }
  };

  useEffect(() => {
    const arrowListener = ({ key }) => {
      if (key === "Left" || key === "ArrowLeft") previousSlide(true);
      if (key === "Right" || key === "ArrowRight") nextSlide(true);
    };
    window.addEventListener("keydown", arrowListener);

    let timeoutID;
    if (autoplaying && timeout !== undefined && timeout !== 0) {
      timeoutID = setTimeout(() => nextSlide(false), timeout * 1000);
    }

    return () => {
      window.removeEventListener("keydown", arrowListener);
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplaying, slideIndex]);

  const swipeable = useSwipeable({
    onSwipedRight: () => previousSlide(true),
    onSwipedLeft: () => nextSlide(true),
  });

  const [loaded, setLoaded] = useState(false);
  const [waited, setWaited] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setWaited(true), 500);
    return () => clearTimeout(id);
  }, []);
  return (
    <Root {...swipeable}>
      <Overlay show={!loaded || !waited} />
      <Background
        slides={slides}
        index={slideIndex}
        onLoad={() => setLoaded(true)}
      />
      <Center>
        <Content to={linkURL}>
          <Text
            css={`
              font-size: 70px;
              ${({ theme }) => theme.media.mobile} {
                font-size: 35px;
              }
              margin-bottom: 10px;
            `}
            font="title"
            component="div"
          >
            {title}
          </Text>
          <Text
            css={`
              font-size: 30px;
              ${({ theme }) => theme.media.mobile} {
                font-size: 15px;
              }
              margin-bottom: 15px;
            `}
            component="div"
          >
            {subtitle}
          </Text>
          <Logo
            src={logo}
            
          />
        </Content>
      </Center>
      <Left>
        <Chevron
          as={ChevronLeft}
          role="button"
          title="Previous slide"
          onClick={() => previousSlide(true)}
          onKeyDown={({ key }) => {
            if (key === " " || key === "Spacebar" || key === "Enter") {
              previousSlide(true);
            }
          }}
          tabIndex={0}
        />
      </Left>
      <Right>
        <Chevron
          as={ChevronRight}
          role="button"
          title="Next slide"
          onClick={() => nextSlide(true)}
          onKeyDown={({ key }) => {
            if (key === " " || key === "Spacebar" || key === "Enter") {
              nextSlide(true);
            }
          }}
          tabIndex={0}
        />
      </Right>
      <Bottom to="/about">
        <Link to={"/contact"}>
          <Brand
            variant="logo-slogan"
            css={`
              height: 100px;
              margin-bottom: 50px;

              &:hover {
                color: rgb(244, 230, 0);
              }

              ${({ theme }) => theme.media.mobile} {
                height: 70px;
                margin-bottom: 30px;
              }
            `}
          />
        </Link>
      </Bottom>
    </Root>
  );
};

export default HomeIndex;
