import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/common/seo";
import Home from "../components/home";

import { withPreview } from "gatsby-source-prismic";

const Index = ({ data }) => {
  const slides = data.prismicHome.data.body.map((slide) => {
    let formattedSlide = {
      type: slide.slice_type,
      title: slide.primary.title,
      subtitle: slide.primary.subtitle,
      logo: slide.primary.logo.fixed.src,
      timeout: slide.primary.timeout || 10,
      linkURL: `/case/${slide.primary.case.uid}`,
    };
    switch (formattedSlide.type) {
      case "video":
        formattedSlide.url = slide.primary.prismic_video.url;
        break;
      case "image":
        formattedSlide.fluidImage = slide.primary.image.fluid;
        formattedSlide.anchor = slide.primary.anchor || "center";
        break;
      case "text":
        formattedSlide.backgroundColor = slide.primary.backgroundColor;
        break;
      default:
    }
    return formattedSlide;
  });
  return (
    <>
      <Home slides={slides} />
      <SEO title="Greener" description="Stay lucid and keep on branding." />
    </>
  );
};

export default withPreview(Index);

export const query = graphql`
  query HomeQuery {
    prismicHome {
      id
      data {
        body {
          ... on PrismicHomeBodyVideo {
            primary {
              subtitle
              title
              prismic_video {
                url
              }
              logo {
                fixed(height: 300) {
                  src
                }
              }
              timeout
              case {
                url
                uid
              }
            }
            slice_type
          }
          ... on PrismicHomeBodyImage {
            primary {
              subtitle
              title
              logo {
                fixed(height: 300) {
                  src
                }
              }
              image {
                fluid(maxHeight: 1920, maxWidth: 1080) {
                  ...GatsbyPrismicImageFluid
                }
              }
              anchor
              timeout
              case {
                url
                uid
              }
            }

            slice_type
          }
          ... on PrismicHomeBodyText {
            primary {
              subtitle
              title
              logo {
                fixed(height: 300) {
                  src
                }
              }
              background_color
              timeout
              case {
                url
                uid
              }
            }
            slice_type
          }
        }
      }
      type
    }
  }
`;
